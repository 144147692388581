<template>
	<section>
		<div class="about_sec">
			<div class="about_title">
				<div class="title_desc">{{ t('about.title') }}</div>
				<p class="slogan">
					{{ t('about.slogan1') }}
					<br />
					{{ t('about.slogan2') }}
				</p>
			</div>
			<div class="slogan_desc">
				<p class="about_desc top">
					{{ t('about.desc_top') }}
				</p>
				<p class="about_desc bottom">
					{{ t('about.desc_bottom') }}
				</p>
			</div>
		</div>
		<div class="contour"></div>
		<div class="partner_section fade_in_up">
			<div class="partner_title">{{ t('about.partner_title') }}</div>
			<div class="about_desc partner">
				{{ t('about.partner_desc') }}
				<br />
				{{ t('about.partner_desc2') }}
			</div>
			<div class="partner_list">
				<ul>
					<li>
						<img src="@/assets/images/eurobet.png" alt="eurobet" />
					</li>
					<li>
						<img src="@/assets/images/softbet.png" alt="softbet" />
					</li>
					<li>
						<img src="@/assets/images/netbet.png" alt="netbet" />
					</li>
					<li>
						<img src="@/assets/images/eurobet.png" alt="eurobet" />
					</li>
					<li>
						<img src="@/assets/images/softbet.png" alt="softbet" />
					</li>
					<li>
						<img src="@/assets/images/netbet.png" alt="netbet" />
					</li>
				</ul>
			</div>
		</div>
	</section>
</template>

<script>
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
	name: 'About',
	setup() {
		const { t } = useI18n({
			userScope: 'global',
			inheritLocale: true,
		});
		return { t };
	},
});
</script>

<style scoped>
@import '../assets/css/about.css';
</style>
